import { Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import JSCookies from "js-cookie";
import { parse } from "psl";

import { LOCALE_COOKIE_NAME } from "../../lib/i18n/i18next/i18next-instance";
import logger from "../../lib/logger-utils";

export const handleChangeLanguage = (
  locale: string | undefined,
  push: (locale: string | undefined) => void,
) => {
  // save selected language in cookie in order to keep it for the current session (and especially if we are on a compass page, we want to keep the language chosen through the other refront of the same domain)
  // need to extract the domain of the url => toto.tata.com -> tata.com
  const extractedDomain = parse(window.location.hostname);
  const domain =
    extractedDomain && extractedDomain["domain"] && extractedDomain["domain"].length > 0
      ? `${extractedDomain["domain"]}`
      : window.location.hostname;
  logger.debug(`Changing locale to ${locale}`);
  JSCookies.set(LOCALE_COOKIE_NAME, locale ?? "fr", {
    domain,
  });
  logger.debug(`Set locale cookie on ${domain} domain with value ${locale}`);
  push(locale);
};

export const getKLanguageSwitcherStyle = (color?: string): SxProps<Theme> => {
  return {
    "& .MuiSelect-select": {
      color: (theme) => `${color || theme.palette.text.primary}`,
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: (theme) => `${alpha(color || theme.palette.text.primary, 0.42)}`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: (theme) => `${color || theme.palette.text.primary}`,
    },
    "& .MuiSelect-icon": {
      color: (theme) => `${color || theme.palette.text.primary}`,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: (theme) => `${alpha(color || theme.palette.text.primary, 0.87)}`,
    },
  };
};
