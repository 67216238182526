import React, { FC } from "react";

import { DefaultIcon } from "@keepeek/refront-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";

import { KIcon } from "../../../../components/common/KIcon";

const fontAwesomeIconsColumn1: string[] = [
  "fa-sliders",
  "fa-magnifying-glass",
  "fa-basket-shopping-simple",
  "fa-basket-shopping-plus",
  "fa-share",
  "fa-down-to-line",
  "fa-up-from-line",
  "fa-download",
  "fa-cloud-arrow-up",
  "fa-link",
  "fa-link-simple",
  "fa-paperclip-vertical",
  "fa-paperclip",
  "fa-brands fa-square-facebook",
  "fa-brands fa-square-x-twitter",
  "fa-brands fa-linkedin",
  "fa-clone",
  "fa-copy",
  "fa-bolt-auto",
  "fa-user-plus",
  "fa-unlock",
  "fa-user",
  "fa-circle-user",
  "fa-square-user",
  "fa-user-group",
  "fa-folder",
  "fa-folder-open",
  "fa-eye",
  "fa-eye-slash",
  "fa-camera",
  "fa-file-image",
  "fa-images",
  "fa-image",
  "fa-file-pdf",
  "fa-file",
  "fa-table-list",
  "fa-objects-column",
  "fa-grid-horizontal",
  "fa-arrow-right-from-bracket",
  "fa-arrow-down-arrow-up",
  "fa-arrow-up-right-from-square",
  "fa-arrow-up-to-line",
  "fa-arrow-rotate-left",
  "fa-arrow-right",
  "fa-arrow-up",
  "fa-arrow-left",
  "fa-arrow-down",
  "fa-caret-down",
  "fa-caret-up",
  "fa-caret-right",
  "fa-chevron-left",
  "fa-chevron-right",
  "fa-chevron-up",
  "fa-chevron-down",
];

const fontAwesomeIconsColumn2: string[] = [
  "fa-file-circle-plus",
  "fa-refresh",
  "fa-up-right-from-square",
  "fa-search-plus",
  "fa-search-minus",
  "fa-square-plus",
  "fa-list-tree",
  "fa-grip",
  "fa-grip-vertical",
  "fa-maximize",
  "fa-remove",
  "fa-tag",
  "fa-square-pen",
  "fa-plus",
  "fa-check",
  "fa-square-check",
  "fa-circle-check",
  "fa-circle-info",
  "fa-circle-chevron-left",
  "fa-circle-question",
  "fa-xmark",
  "fa-circle-xmark",
  "fa-triangle-exclamation",
  "fa-square",
  "fa-trash-can-list",
  "fa-down-left-and-up-right-to-center",
  "fa-trash",
  "fa-star-sharp",
  "fa-quote-right",
  "fa-expand",
  "fa-house",
  "fa-square-minus",
  "fa-bars",
  "fa-ellipsis-vertical",
  "fa-minus",
  "fa-forward",
  "fa-backward",
  "fa-volume-slash",
  "fa-volume",
  "fa-play",
  "fa-circle-play",
  "fa-circle-pause",
  "fa-repeat",
  "fa-palette",
  "fa-table-layout",
  "fa-gear",
  "fa-icons",
  "fa-input-text",
  "fa-sidebar",
  "fa-user-group",
  "fa-rectangles-mixed",
  "fa-rectangle-code",
  "fa-arrow-up-arrow-down",
  "fa-cloud-arrow-up",
  "fa-shield-quartered",
  "fa-cloud-arrow-down",
  "fa-file-plus",
  "fa-pen",
  "fa-pen-to-square",
  "fa-floppy-disk",
  "fa-crop-simple",
];

const CustomIconsField: FC<React.PropsWithChildren<FieldProps>> = function (props) {
  const { ArrayField } = props.registry.fields;
  const { t } = useTranslation("admin");

  return (
    <>
      <Accordion sx={{ marginBottom: "24px" }}>
        <AccordionSummary expandIcon={<KIcon className="fa-solid fa-chevron-down"></KIcon>}>
          <KIcon
            className="fa-regular fa-circle-info fa-xl"
            sx={{ padding: (theme) => theme.spacing(2) }}
          ></KIcon>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={"h5"} component={"div"}>
              {t("rjsf.icons.information-panel.title")}
            </Typography>
            <Typography>{t("rjsf.icons.information-panel.description")}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", gap: 6 }}>
            <TableContainer>
              <Table>
                <TableHead sx={{ background: "#EEEEEE" }}>
                  <TableRow>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}></TableCell>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }} align="right">
                      {t("rjsf.icons.key")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fontAwesomeIconsColumn1.map((fontAwesomeIconColumn1) => (
                    <TableRow key={fontAwesomeIconColumn1}>
                      <TableCell>
                        <DefaultIcon className={`${fontAwesomeIconColumn1} fa-lg`} />
                      </TableCell>
                      <TableCell align="right">{fontAwesomeIconColumn1}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table>
                <TableHead sx={{ background: "#EEEEEE" }}>
                  <TableRow>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }}></TableCell>
                    <TableCell sx={{ fontSize: "16px", fontWeight: "bold" }} align="right">
                      {t("rjsf.icons.key")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fontAwesomeIconsColumn2.map((fontAwesomeIcon) => (
                    <TableRow key={fontAwesomeIcon}>
                      <TableCell>
                        <DefaultIcon className={`${fontAwesomeIcon} fa-lg`} />
                      </TableCell>
                      <TableCell align="right">{fontAwesomeIcon}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ArrayField {...props} />
    </>
  );
};

export default CustomIconsField;
