import { isClientSide } from "@keepeek/commons";
import * as Sentry from "@sentry/nextjs";
import { AxiosInstance } from "axios";
import { setupCache } from "axios-cache-interceptor";
import { AxiosCacheInstance } from "axios-cache-interceptor/src/cache/axios";

import logger from "../../lib/logger-utils";

function addExecutionTimeLogging(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use((x) => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    // @ts-ignore : add custom objet : meta
    x.meta = x.meta || {};
    // @ts-ignore : add custom objet : meta
    x.meta.requestStartedAt = new Date().getTime();
    return x;
  });
  axiosInstance.interceptors.response.use((x) => {
    // @ts-ignore : use custom objet : meta
    const timeInMs = new Date().getTime() - x.config.meta.requestStartedAt;
    logger.debug(
      `[AXIOS] URL=${x.config.url} Method=${
        x.config.method
      } ExecutionTime(ms)=${timeInMs} Size(octet)=${x.headers["content-length"]} Accept-Language=${
        x.config?.headers?.["Accept-Language"] ?? ""
      } Status=${x.status}`,
    );
    return x;
  });
}

function addSentryErrorTracker(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(
    (res) => res,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  );
  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      // Don't send 401, 403, 404 error to Sentry because these are normal errors send by the API
      if (
        error.response.status !== 401 &&
        error.response.status !== 403 &&
        error.response.status !== 404
      ) {
        Sentry.captureException(error);
      }
      return Promise.reject(error);
    },
  );
}

const addAxiosInterceptors = (axiosInstance: AxiosInstance): void => {
  // Save axios setup in global object in development mode.
  // Fast refresh execute all script files after a refresh and
  // Axios interceptors will be duplicated.
  // https://github.com/vercel/next.js/issues/7811
  if (process.env.NODE_ENV === "development") {
    if (!(global as any).AXIOS_INTERCEPTORS) {
      logger.debug("[axios] Add Axios interceptors development");
      addExecutionTimeLogging(axiosInstance);
      if (isClientSide()) {
        addCache(axiosInstance);
      }
      (global as any).AXIOS_INTERCEPTORS = "init";
    }
  } else {
    logger.debug("[axios] Add Axios interceptors production");
    addExecutionTimeLogging(axiosInstance);
    if (isClientSide()) {
      addSentryErrorTracker(axiosInstance);
      addCache(axiosInstance);
    }
  }
};

const addCache = (axiosInstance: AxiosInstance): AxiosCacheInstance => {
  return setupCache(axiosInstance, {
    ttl: 1000 * 60, //1 minute
    cachePredicate: {
      statusCheck: (status) => status >= 200 && status < 400,
      responseMatch: ({ config }) => {
        if (!config.url || (config.method !== "GET" && config.method !== "get")) {
          return false;
        }
        if (config.url === "/api/dam") {
          /**
           * allows not to produce several API calls to this resource in the following cases:
           * - From "commons", validation of user authentication with a call to /api/dam
           * - From "refront-core", retrieval of current user information (a call to /api/dam is made before to retrieve the API link of the current user)
           */
          return true;
        } else if (/^\/api\/dam\/users\/\d+$/.test(config.url)) {
          /**
           * allows not to produce several API calls to this resource in the following cases:
           * - From "refront-core", retrieval of current user information
           * - From "refront-components", retrieval of current user information for the proper functioning of the import module
           */
          return true;
        } else {
          // no cache
          return false;
        }
      },
    },
  });
};

export default addAxiosInterceptors;
