import React, { FC } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { KIcon } from "../../../../../components/common/KIcon";

export type NoFilterProps = {
  onAddFilter: (previousIndex?: number, childIndex?: number) => void;
  onAddSection: (previousIndex?: number) => void;
};
export const NoFilter: FC<NoFilterProps> = function ({ onAddFilter, onAddSection }) {
  const { t } = useTranslation("admin");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "500px",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4" color="text.secondary">
        <KIcon
          fontSize="large"
          className="fa-circle-info"
          sx={{
            verticalAlign: "middle",
            marginRight: (theme) => theme.spacing(3),
          }}
        />
        {t("rjsf.business-filter.no-filter.title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 3,
          p: 2,
        }}
      >
        <Button onClick={() => onAddFilter(0, undefined)} variant="outlined">
          {t("rjsf.business-filter.no-filter.add-filter.button")}
        </Button>
        <Button onClick={() => onAddSection(0)} variant="outlined">
          {t("rjsf.business-filter.no-filter.add-section.button")}
        </Button>
      </Box>
    </Box>
  );
};
