import React from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, useTheme } from "@mui/material";
import clsx from "clsx";

import { KIcon } from "../../../../../components/common/KIcon";
import { BasketsWidget } from "../../../../../models/configuration/components/basketsWidget";

const WidgetCardSelectorBaskets = function ({
  testId = "WidgetCardSelectorBaskets",
  className,
  id,
}: BasketsWidget & BaseComponentProps) {
  const theme = useTheme();
  return (
    <Box
      className={clsx("WidgetCardSelectorBaskets", className)}
      data-testid={testId}
      title={id}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        flexShrink: 1,
      }}
    >
      <Box
        style={{
          backgroundColor: theme.palette.grey[400],
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: theme.spacing(1),
          flexGrow: 1,
        }}
      >
        <KIcon className="fa-basket-shopping" />
      </Box>
    </Box>
  );
};

export default WidgetCardSelectorBaskets;
