import React, { FC } from "react";

import { Checkbox, FormControl, FormControlLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const UrlLink: FC<
  React.PropsWithChildren<{
    url?: string;
    onUrlChange: (data: string) => void;
    onOpenInNewTabChange: (data: boolean) => void;
    openInNewTab: boolean;
  }>
> = function ({ url, onUrlChange, onOpenInNewTabChange, openInNewTab }) {
  const { t } = useTranslation("admin");
  return (
    <>
      <TextField
        label={t("locale.links.link.href")}
        value={url || ""}
        onChange={(event) => onUrlChange(event.target.value)}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <FormControlLabel
          control={
            <Checkbox
              value={openInNewTab}
              checked={openInNewTab}
              onChange={(event) => onOpenInNewTabChange(event.target.checked)}
            />
          }
          label={t("locale.links.link.open.in.new.tab") as string}
        />
      </FormControl>
    </>
  );
};

export default UrlLink;
