import React from "react";

import { InputLabel, MenuItem, Select } from "@mui/material";
import { Widget, WidgetProps } from "@rjsf/utils";
import { isArray } from "lodash";

import { KIcon } from "../../../../components/common/KIcon";

const CustomIconsWidget: Widget = function ({ onChange, value, label, options }: WidgetProps) {
  if (!options || !isArray(options.icons)) {
    return null;
  }
  return (
    <>
      <InputLabel id="icon-label">{label}</InputLabel>
      <Select
        labelId="icon-label"
        value={value}
        label={label}
        aria-label={label}
        onChange={({ target }) => onChange(target.value)}
      >
        {options.icons.map((icon) => {
          const iconTitle = Object.keys(icon)[0];
          const iconValue = icon[iconTitle];
          return (
            <MenuItem key={iconTitle} value={iconValue} title={iconTitle}>
              <KIcon className={iconValue} />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default CustomIconsWidget;
