import React, { FC } from "react";

import { ComponentOverrideKey } from "@keepeek/refront-components";
import Head from "next/head";
import { useTranslation } from "react-i18next";

import { getCustomerOverrideComponent } from "../../lib/overrides";
import { useHeaderMetas } from "../../providers/config/hooks/useHeaderMetas";
import { useFavicon } from "../../providers/favicon/useFavicon";

const KHead: FC<React.PropsWithChildren<unknown>> = function () {
  const { t } = useTranslation();
  const { title, description, og } = useHeaderMetas();
  const { favicon32Src, favicon192Src, faviconFormat } = useFavicon();

  const titleRendered = title ? `${title} — ${t("app.title")}` : t("app.title");
  const ogType = og?.type || "website";
  const ogUrl = og?.url || window.location.href;
  return (
    <Head>
      <title key="title">{titleRendered}</title>
      <>
        {favicon32Src && faviconFormat && (
          <link key="favicon32" rel="icon" type={faviconFormat} sizes="32x32" href={favicon32Src} />
        )}
        {favicon192Src && faviconFormat && (
          <link
            key="favicon192"
            rel="icon"
            type={faviconFormat}
            sizes="192x192"
            href={favicon192Src}
          />
        )}
      </>
      {description && <meta key="description" content={description} />}
      {(title || og?.title) && (
        <meta key="ogTitle" property="og:title" content={og?.title ?? title} />
      )}
      <meta key="ogType" property="og:type" content={ogType} />
      <meta key="ogUrl" property="og:url" content={ogUrl} />
      {og?.image && <meta key="ogImage" property="og:image" content={og.image} />}
      {(description || og?.description) && (
        <meta
          key="ogDescription"
          property="og:description"
          content={og?.description ?? description}
        />
      )}
      <meta
        key="ogSiteName"
        property="og:site_name"
        content={og?.siteName ?? (t("app.title") as string)}
      />
      <meta
        key="viewport"
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
    </Head>
  );
};

export default getCustomerOverrideComponent(ComponentOverrideKey.KHead, KHead);
