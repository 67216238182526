import React, { ComponentType, Dispatch, SetStateAction, useState, useMemo } from "react";

import { BaseComponentProps, KIconProps } from "@keepeek/refront-components";
import { Box, Typography, IconButton } from "@mui/material";
import clsx from "clsx";

import { TreeHeader } from "./TreeHeader";
import { TreeLeafs, TreeLeafsProps } from "./TreeLeafs";
import { TreeEditOption, genericMemo } from "./types";

import { TreeEditProps } from ".";

export type TreeLeafWithChildrensProps<T> = {
  allowRemove?: TreeEditProps<T>["allowRemove"];
  onEdit?: TreeEditProps<T>["onEdit"];
  onRemove?: TreeEditProps<T>["onRemove"];
  onToggle?: TreeEditProps<T>["onToggle"];
  onDragStart?: TreeEditProps<T>["onDragStart"];
  onAddItem?: TreeEditProps<T>["onAddItem"];
  onAddParentItem?: TreeEditProps<T>["onAddParentItem"];
  KIcon: ComponentType<KIconProps>;
  item: TreeEditOption<T>;
  pathEdit: TreeLeafsProps<T>["pathEdit"];
  index: number;
  setNewItems: Dispatch<SetStateAction<TreeEditOption<T>[]>>;
  parentItem?: TreeLeafsProps<T>["parentItem"];
} & BaseComponentProps;

/**
 * Represent a tree leaf with childrens
 */
export const TreeLeafWithChildrens = genericMemo(function TreeWithChildsHeader<T>({
  id,
  className,
  sx,
  testId = "TreeLeafWithChildrens",
  allowRemove,
  onEdit,
  onRemove,
  onToggle,
  onDragStart,
  onAddParentItem,
  onAddItem,
  KIcon,
  item,
  pathEdit,
  index,
  setNewItems,
  parentItem,
}: React.PropsWithChildren<TreeLeafWithChildrensProps<T>>) {
  const [open, setOpen] = useState(true);

  const childPathEdit = useMemo(() => {
    return [...pathEdit, index];
  }, [index, pathEdit]);

  return (
    <Box
      className={clsx("TreeLeafWithChildrens", className)}
      id={id}
      data-testid={testId}
      sx={{
        pt: 1,
        pb: 1,
        backgroundColor: (theme) => theme.palette.grey[200],
        borderRadius: "3px",
        ...sx,
      }}
    >
      <TreeHeader
        allowRemove={allowRemove}
        onEdit={onEdit}
        onRemove={onRemove}
        KIcon={KIcon}
        item={item}
        label={
          <Typography variant="body2" sx={{ fontWeight: "bold", wordBreak: "break-word" }}>
            <KIcon
              sx={{ verticalAlign: "middle", mr: 1, fontSize: "1rem" }}
              className="fa-list-tree"
            />
            {item.label}
          </Typography>
        }
        actions={
          <IconButton
            aria-label={`Toggle ${item.label}`}
            onClick={(event) => {
              onToggle && onToggle(event, item, parentItem);
              setOpen((open) => !open);
            }}
          >
            <KIcon
              sx={{ verticalAlign: "middle", fontSize: "1rem" }}
              className={open ? "fa-chevron-up" : "fa-chevron-down"}
            />
          </IconButton>
        }
        parentItem={parentItem}
      >
        {open && item && item.children && (
          <TreeLeafs
            items={item.children}
            allowRemove={allowRemove}
            onEdit={onEdit}
            onRemove={onRemove}
            onDragStart={onDragStart}
            onAddParentItem={onAddParentItem}
            onAddItem={onAddItem}
            KIcon={KIcon}
            pathEdit={childPathEdit}
            setNewItems={setNewItems}
            parentItem={item}
          />
        )}
      </TreeHeader>
    </Box>
  );
});
