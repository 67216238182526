import React, { FC } from "react";

import { FieldFolder } from "@keepeek/refront-components";
import { Box, Container, FormControl, Paper, TextField } from "@mui/material";
import { FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { KIcon } from "../../../../components/common/KIcon";
import { Link } from "../../../../models/configuration/components/kMenuWidget";
import { LinkType } from "../../../../models/configuration/components/kMenuWidget";
import { frontEditState } from "../../../../providers/frontEdit/atoms/frontEdit";
import LinkTypeSelector from "../components/LinkTypeSelector";
import UrlLink from "../components/UrlLink";

const CustomMenuLinksField: FC<React.PropsWithChildren<FieldProps<Link>>> = function ({
  formData,
  onChange,
}) {
  const { t } = useTranslation("admin");
  const frontEdit = useRecoilValue(frontEditState);
  const allowedLinkTypes = Object.values(LinkType).filter(
    (type) => type !== LinkType.Didomi && type !== LinkType.Mail,
  );
  const type: Link["linkType"] =
    (formData?.linkType && (formData?.linkType as LinkType)) || LinkType.URL;
  const href: Link["href"] = formData?.href;
  const label: Link["label"] = formData?.label || "";
  const openInNewTab: Link["openInNewTab"] = formData?.openInNewTab;
  const folderId: Link["folderId"] = formData?.folderId;

  const handleChange = (data: Link) => onChange(data);

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: 4,
        margin: 0,
      }}
      component={Paper}
    >
      <Box>
        <TextField
          label={t("label")}
          value={label}
          onChange={(event) =>
            handleChange({
              ...formData,
              label: event.target.value,
            })
          }
          fullWidth
          margin="normal"
        />
        <LinkTypeSelector
          allowedLinkTypes={allowedLinkTypes}
          type={type}
          onChange={(data) =>
            handleChange({
              ...formData,
              label: formData?.label || "",
              linkType: data,
              href: data === LinkType.URL ? "" : formData?.href,
            })
          }
          frontEdit={frontEdit}
        />
        {type === LinkType.URL && (
          <UrlLink
            onUrlChange={(data) =>
              handleChange({
                ...formData,
                label: formData?.label || "",
                href: data,
              })
            }
            onOpenInNewTabChange={(data) =>
              handleChange({
                ...formData,
                label: formData?.label || "",
                openInNewTab: data,
              })
            }
            openInNewTab={(openInNewTab || false) as boolean}
            url={href}
          />
        )}
        {type === LinkType.Folder && (
          <FormControl fullWidth>
            <FieldFolder
              helperText={t("components.mosaicElement.folder.description")}
              title=""
              forceSelection={true}
              multiple={false}
              initialFolderIds={folderId ? [folderId] : []}
              onChange={(values) =>
                handleChange({
                  ...formData,
                  label: formData?.label || "",
                  folderId: values && values.length > 0 ? values[0] : undefined,
                })
              }
              KIcon={KIcon}
            />
          </FormControl>
        )}
      </Box>
    </Container>
  );
};

export default CustomMenuLinksField;
