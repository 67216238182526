import React from "react";

import { KIconProps, DefaultIcon } from "@keepeek/refront-components";
import { Icon } from "@mui/material";
import clsx from "clsx";
import Image from "next/image";

import { Icons } from "../../../models/configuration/icons";
import { useConfiguration } from "../../../providers/config/hooks";
import { CONFIGURATION_SECTION_PATH } from "../../layout/adminMenu";
import { KMaterialIcon } from "../KMaterialIcon";
import { cleanUpClassName } from "./utils";

/**
 * Keepeek overridable icon
 */
export const KIcon = function ({ children, className, ...rest }: KIconProps) {
  const iconsConfig = useConfiguration<Icons>(CONFIGURATION_SECTION_PATH.ICONS);

  const conf = iconsConfig?.icons?.find(
    (icon: any) => icon.key === children || className?.includes(icon.key),
  );

  // Override case
  if (conf?.key) {
    const cleanedClassName = cleanUpClassName(conf.key, className);

    if (conf.svg) {
      /* Force 24x24px to match Material UI rule specified in https://material-ui.com/components/icons/ :
       * SVG elements should be scaled for a 24x24px viewport, so the resulting icon can be used as is,
       * or included as a child for other Material-UI components that use icons.
       */
      return (
        <Icon className={clsx("KIcon", cleanedClassName)} sx={{ overflow: "unset" }} {...rest}>
          <Image width={24} height={24} src={conf.svg} alt={"icon"} aria-hidden={true} />
        </Icon>
      );
    } else if (conf.material) {
      return conf.material.startsWith("fa-") ? (
        <DefaultIcon className={clsx("KIcon", cleanedClassName, conf.material)} {...rest} />
      ) : (
        <KMaterialIcon
          className={clsx("KIcon", cleanedClassName)}
          iconKey={conf.material}
          // Replace small fontSize by 1.251rem to avoid pixelated icons (RF-2689).
          sx={{ fontSize: rest.fontSize === "small" ? "1.251rem" : undefined }}
          {...rest}
        />
      );
    }
  }

  // Default case
  return <DefaultIcon className={clsx("KIcon", className)} {...rest} />;
};
