const reg = new RegExp(`fa-(\\S+)`, "g");

/**
 * Clean up className from Font Awesome specific className
 * @param className className
 * @param iconKey icon key
 */
export const cleanUpClassName = function (iconKey: string, className?: string): string | undefined {
  let cleanedClassName = className;

  if (iconKey.startsWith("fa-")) {
    cleanedClassName = className?.replaceAll(reg, "");
  }

  return cleanedClassName;
};
