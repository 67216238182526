import React from "react";

import { isClientSide, isServerSide } from "@keepeek/commons";
import { NoSsr } from "@mui/material";
import JSCookies from "js-cookie";
import { useRouter } from "next/router";

import createEmotionCache from "../../src/lib/emotion";
import { KWaiter } from "../components/common/KWaiter";
import App, { AppProps } from "../containers/App";
import { updateBrowserLocale } from "../lib/axios/axios-utils";
import { LOCALE_COOKIE_NAME } from "../lib/i18n/i18next/i18next-instance";
import logger from "../lib/logger-utils";

import "@fortawesome/fontawesome-pro/css/fontawesome.css";
import "@fortawesome/fontawesome-pro/css/solid.css";
import "@fortawesome/fontawesome-pro/css/light.css";
import "@fortawesome/fontawesome-pro/css/brands.css";
import "@fortawesome/fontawesome-pro/css/regular.css";

import "../styles/globals.css";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp = function ({ Component, pageProps, emotionCache = clientSideEmotionCache }: AppProps) {
  const { locale, push, pathname, query, asPath, locales } = useRouter();

  updateBrowserLocale(locale);

  // because of a recoil memory leak when using selector / selectorFamily we are forced to disable the rendering of components in SSR in order not to explode the server memory ...
  // even version 0.4.0 of Recoil which allows you to manage the cache does not help...
  // see RF-851 for more detail
  if (isServerSide()) {
    return null;
  }

  // We change URL (and locale) if we detect that current locale is different from locale cookie value.
  // this case is quite rare and happens when we come from another refront (like a compass page) and we want to keep the language from one refront to another
  if (isClientSide()) {
    const localeCookie = JSCookies.get(LOCALE_COOKIE_NAME);
    if (localeCookie && locale !== localeCookie && locales?.includes(localeCookie)) {
      logger.info(`Change locale to cookie locale ${localeCookie}`);
      push({ pathname, query }, asPath, { locale: localeCookie });
      return <KWaiter />;
    }
  }

  return (
    <NoSsr>
      <App pageProps={pageProps} Component={Component} emotionCache={emotionCache} />
    </NoSsr>
  );
};

export default MyApp;
