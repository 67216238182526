import React, { FC, useState } from "react";

import { SanitizeHtml } from "@keepeek/refront-components";
import { Box, Popover, Typography } from "@mui/material";
import { DescriptionFieldProps, FormContextType, StrictRJSFSchema } from "@rjsf/utils";
import { Element } from "domhandler";
import { domToReact, HTMLReactParserOptions } from "html-react-parser";
import isString from "lodash/isString";

import { KIcon } from "../../../../components/common/KIcon";

const CustomDescriptionField = function <T, S extends StrictRJSFSchema, F extends FormContextType>(
  props: DescriptionFieldProps<T, S, F>,
) {
  const { id, description } = props;
  if (description) {
    return (
      <Typography id={id} variant="subtitle2" sx={{ marginTop: "5px" }}>
        {isString(description) ? (
          <SanitizeHtml
            html={description}
            sanitizeOptions={{ allowedAttributes: false, allowedTags: false }}
            parseOptions={parseOptions}
          />
        ) : (
          description
        )}
      </Typography>
    );
  }

  return null;
};

const parseOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (
      domNode.nodeType === 1 &&
      domNode.type === "tag" &&
      (domNode as Element)?.name === "popover" &&
      "helptext" in (domNode as Element)?.attribs
    ) {
      return (
        <CustomPopover
          content={domToReact((domNode as Element).children)}
          helpText={(domNode as Element)?.attribs["helptext"]}
        />
      );
    }
  },
};

const CustomPopover: FC<any> = function ({ helpText, content }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  if (!helpText || !content) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Box component={"span"} sx={{ borderBottom: "1px dotted", marginRight: 1, cursor: "help" }}>
          {helpText}
        </Box>
        <KIcon className="fa-circle-question" sx={{ fontSize: "1.2em", cursor: "help" }} />
      </Box>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        disableRestoreFocus
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography variant="body2" sx={{ pt: 1, pb: 1, pr: 2, pl: 2 }}>
          {content}
        </Typography>
      </Popover>
    </>
  );
};

export default CustomDescriptionField;
