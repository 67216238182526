import React, { ComponentType, ReactNode } from "react";

import { BaseComponentProps, KIconProps } from "@keepeek/refront-components";
import { Box, IconButton } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { TreeLeafsProps } from "./TreeLeafs";
import { TreeEditOption, genericMemo } from "./types";

import { TreeEditProps } from ".";

export type TreeHeaderProps<T> = {
  allowRemove?: TreeEditProps<T>["allowRemove"];
  onEdit?: TreeEditProps<T>["onEdit"];
  onRemove?: TreeEditProps<T>["onRemove"];
  KIcon: ComponentType<KIconProps>;
  item: TreeEditOption<T>;
  label: ReactNode;
  actions?: ReactNode;
  parentItem?: TreeLeafsProps<T>["parentItem"];
} & BaseComponentProps;

export const TreeHeader = genericMemo(function TreeHeader<T>({
  id,
  className,
  sx,
  testId = "TreeHeader",
  children,
  allowRemove,
  onEdit,
  onRemove,
  KIcon,
  item,
  label,
  actions,
  parentItem,
}: React.PropsWithChildren<TreeHeaderProps<T>>) {
  const { t } = useTranslation("refront-components");

  return (
    <Box
      className={clsx("TreeHeader", className)}
      id={id}
      data-testid={testId}
      onClick={(event) => {
        // Clicking on the entire filter opens the edition popup,
        // We disable this behavior for section with "item.children === undefined"
        if (onEdit && item.children === undefined) {
          onEdit(event, item, parentItem);
        }
      }}
      sx={{
        p: 1,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        borderRadius: "3px",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {label}
        <Box
          onClick={(event) => event.stopPropagation()}
          sx={{
            cursor: "default",
            flexShrink: 0,
          }}
        >
          {allowRemove && (
            <IconButton
              aria-label={t("components.field.tree-edit.header.action.remove.label", {
                name: item.label,
              })}
              data-testid={`remove-${item.id}`}
              onClick={(event) => {
                onRemove && onRemove(event, item, parentItem);
              }}
            >
              <KIcon
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: "1rem" }}
                className="fa-trash"
              />
            </IconButton>
          )}
          {onEdit && (
            <IconButton
              aria-label={t("components.field.tree-edit.header.action.edit.label", {
                name: item.label,
              })}
              data-testid={`edit-${item.id}`}
              onClick={(event) => onEdit(event, item, parentItem)}
            >
              <KIcon
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: "1rem" }}
                className="fa-pen"
              />
            </IconButton>
          )}
          {actions}
        </Box>
      </Box>
      {children}
    </Box>
  );
});
